<template>
  <div>汇付开户银行选项</div>
</template>

<script>
export default {

}
</script>

<style>

</style>